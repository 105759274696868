import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import loader_lottie from "./assets/logo_animation.json";
import Lottie from "react-lottie";
// import "aos/dist/aos.css";

const loaderOptions = {
  loop: true,
  autoplay: true,
  animationData: loader_lottie,
  rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
const ReduxContainer = React.lazy(() => import("./ReduxContainer"));


root.render(
  <Suspense
    fallback={
      <div className="Loader-glass">
          <Lottie
            options={loaderOptions}
            height={180}
            width={180}
            speed="2.5"
            className="kuber d-none"
          />
      </div>
    }
  >
    <ReduxContainer />
  </Suspense>
);
